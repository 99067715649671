<template lang="pug">
.roles-shell
    .box
        .box__header
            TitlePage(
                title="Compare Target Loc"
                :breadcrumb="breadcrumb"
                )
        .box__body
            router-view
</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
export default {
    name: 'pageCompareTargetShell',
    components: {
        TitlePage,
    },
    data() {
        return {
            // 
        }
    },
    computed: {
        route() {
            return this.$route;
        },
        // id() {
        //     return this.$route.params.id;
        // },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'Compare Target Loc',
                    url: '/compare-target-loc',
                },
            ];
            // console.log('this.route.matched[0]', this.route.matched[0])
            // console.log('this.route.matched[1].name', this.route.matched[1].name)
            if (this.route.matched[0] && this.route.matched[0].components.default.name === 'pageCompareTargetShell' && this.route.matched[1]) {
                switch(this.route.matched[1].name) {
                    case 'pageCompareTargetDetail':
                        breadcrumb.push({
                            name: 'Detail',
                            url: '/compare-target-loc/detail',
                        });
                        break;
                    default:
                        // code block
                }
            }

            return breadcrumb;
        },
    },
    watch: {
        route() {},
        id() {},
        breadcrumb() {},
    },
    created() {
        // 
    },
    mounted() {
        // 
    },
}
</script>
